exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-alicia-keller-tsx": () => import("./../../../src/pages/alicia_keller.tsx" /* webpackChunkName: "component---src-pages-alicia-keller-tsx" */),
  "component---src-pages-angebot-kosten-tsx": () => import("./../../../src/pages/angebot_kosten.tsx" /* webpackChunkName: "component---src-pages-angebot-kosten-tsx" */),
  "component---src-pages-anna-gossweiler-tsx": () => import("./../../../src/pages/anna_gossweiler.tsx" /* webpackChunkName: "component---src-pages-anna-gossweiler-tsx" */),
  "component---src-pages-carina-galli-tsx": () => import("./../../../src/pages/carina_galli.tsx" /* webpackChunkName: "component---src-pages-carina-galli-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-orlando-luescher-tsx": () => import("./../../../src/pages/orlando_luescher.tsx" /* webpackChunkName: "component---src-pages-orlando-luescher-tsx" */),
  "component---src-pages-ricardo-cruz-tsx": () => import("./../../../src/pages/ricardo_cruz.tsx" /* webpackChunkName: "component---src-pages-ricardo-cruz-tsx" */)
}

